@import url(https://fonts.googleapis.com/css?family=Unica+One);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* font-family: LatoWeb; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1 {
  color: #1c1b20;
  margin: 5px 0 25px;
  font-size: 1.7rem;
  font-weight: 700;
  text-transform: capitalize;
  display: flex;
  align-items: center;
}
h1 svg {
  /* vertical-align: middle; */
  margin-right: 8px;
  /* padding-top: 5px; */
}

h2,
h3 {
  font-weight: 700;
  letter-spacing: 0.2px;
}
h4 {
  font-weight: 550;
}
ul li,
ul li a,
textarea,
p,
h5 {
  font-weight: 450;
}
/* span {
  font-weight: 500;
} */

.MuiAvatar-root.MuiAvatar-colorDefault {
  background-color: #e1e5ef;
  text-transform: uppercase;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}
:hover::-webkit-scrollbar-thumb {
  display: block;
}
::-webkit-scrollbar-thumb {
  background: #98989c;
  background: #d2d2d2;
  border-radius: 8px;
  display: none;
}
input::-webkit-input-placeholder {
  color: #b2b2b2;
}
input:-ms-input-placeholder {
  color: #b2b2b2;
}
input::-ms-input-placeholder {
  color: #b2b2b2;
}
input::placeholder {
  color: #b2b2b2;
}
.mainInnerStyles::-webkit-scrollbar-track {
  /* background: #f1f1f1; */
  background: transparent;
}

.orderStatus {
  padding: 4px 15px 3px;
  border-radius: 20px;
  color: #fff;
  opacity: 0.8;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: -0.2px;
}

.sketch-picker {
  margin-bottom: 20px !important;
}
.mainInnerView {
  padding: 20px;
}
.rightInnerView {
  padding: 20px;
}

.listPaper {
  background: #fff;
  border: 1px solid #e8e8e8;
  padding: 20px;
}

hr {
  border: 0px;
  border-bottom: 1px solid #e2e2e2;
}
a {
  color: #EBAE25;
  text-decoration: none;
}

.modalRight {
  position: absolute;
  right: 0;
  height: 100vh;
  border-radius: 0px !important;
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  overflow: overlay;
  opacity: 0;
}
.modalRight.fade {
  opacity: 1;
  right: -400px;
  transition: none !important;
  -webkit-animation: slideRight 0.5s forwards;
  animation: slideRight 0.5s forwards;
}
.modalRight.fadeM {
  opacity: 1;
  right: -400px;
  transition: none !important;
  -webkit-animation: slideRightMax 0.5s forwards;
  animation: slideRightMax 0.5s forwards;
}
.modalRight.fadeMW {
  opacity: 1;
  right: -650px;
  transition: none !important;
  -webkit-animation: slideRightMaxWidth 0.5s forwards;
  animation: slideRightMaxWidth 0.5s forwards;
}

.modalRight .react-datepicker-wrapper {
  display: block;
}

.react-datepicker__tab-loop .react-datepicker-popper {
  z-index: 999;
  left: -70px !important;
}
.modalRight .react-datepicker-popper {
  left: -30px !important;
}
.react-datepicker__week .react-datepicker__day--selected,
.react-datepicker__week .react-datepicker__day--in-selecting-range,
.react-datepicker__week .react-datepicker__day--in-range,
.react-datepicker__week .react-datepicker__month-text--selected,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range,
.react-datepicker__week .react-datepicker__month-text--in-range,
.react-datepicker__week .react-datepicker__quarter-text--selected,
.react-datepicker__week .react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range,
.react-datepicker__week .react-datepicker__day--selected:hover,
.react-datepicker__week .react-datepicker__day--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__day--in-range:hover,
.react-datepicker__week .react-datepicker__month-text--selected:hover,
.react-datepicker__week .react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__month-text--in-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--selected:hover,
.react-datepicker__week
  .react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__quarter-text--in-range:hover,
.react-datepicker__week .react-datepicker__year-text--selected:hover,
.react-datepicker__week .react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__week .react-datepicker__year-text--in-range:hover {
  background: #EBAE25;
}
form .react-datepicker__day--keyboard-selected,
form .react-datepicker__month-text--keyboard-selected,
form .react-datepicker__quarter-text--keyboard-selected,
form .react-datepicker__year-text--keyboard-selected {
  background-color: transparent;
  color: #000;
}
form .react-datepicker__day--keyboard-selected:hover,
form .react-datepicker__month-text--keyboard-selected:hover,
form .react-datepicker__quarter-text--keyboard-selected:hover,
form .react-datepicker__year-text--keyboard-selected:hover {
  background-color: #f0f0f0;
}
/* form .react-datepicker__day--today {
  background: #EBAE25;
  color: #fff;
} */

.map-container .map {
  position: relative !important;
}
.modalRight h3 {
  font-size: 20px;
  margin: 20px 15px;
}
.rc-time-picker-panel {
  z-index: 99999 !important;
}
.Snackbar_snackbar-wrapper__ocbPJ {
  z-index: 99999 !important;
}
.mainInnerView .MuiTableContainer-root::-webkit-scrollbar {
  width: 6px;
  height: 7px;
}
.slide-up {
  transition: opacity 0.4s ease-in-out;
}

.slide-down {
  transition: opacity 0.4s ease-in-out;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 999 !important;
}

/* @keyframes slide-up {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
} */

@-webkit-keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

@-webkit-keyframes slide-width {
  0% {
    width: 0;
    opacity: 0;
    right: 0%;
    -webkit-transform: translateX(0%) scaleX(0.4);
            transform: translateX(0%) scaleX(0.4);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    width: 400px;
    opacity: 1;
    /* transform: translateY(0%); */
    -webkit-transform: translateX(-400px) scaleX(0.4);
            transform: translateX(-400px) scaleX(0.4);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

@keyframes slide-width {
  0% {
    width: 0;
    opacity: 0;
    right: 0%;
    -webkit-transform: translateX(0%) scaleX(0.4);
            transform: translateX(0%) scaleX(0.4);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  100% {
    width: 400px;
    opacity: 1;
    /* transform: translateY(0%); */
    -webkit-transform: translateX(-400px) scaleX(0.4);
            transform: translateX(-400px) scaleX(0.4);
    -webkit-transform-origin: right;
            transform-origin: right;
  }
}

@-webkit-keyframes slideRight {
  0% {
    right: -400px;
  }
  100% {
    right: 0px;
  }
}

@keyframes slideRight {
  0% {
    right: -400px;
  }
  100% {
    right: 0px;
  }
}
@-webkit-keyframes slideRightMax {
  0% {
    right: -500px;
  }
  100% {
    right: 0px;
  }
}
@keyframes slideRightMax {
  0% {
    right: -500px;
  }
  100% {
    right: 0px;
  }
}
@-webkit-keyframes slideRightMaxWidth {
  0% {
    right: -650px;
  }
  100% {
    right: 0px;
  }
}
@keyframes slideRightMaxWidth {
  0% {
    right: -650px;
  }
  100% {
    right: 0px;
  }
}
@-webkit-keyframes slideHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 30px; /* your line height here */
    opacity: 1;
  }
}
@keyframes slideHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 30px; /* your line height here */
    opacity: 1;
  }
}

@-webkit-keyframes slideMaxHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 60px; /* your line height here */
    opacity: 1;
  }
}

@keyframes slideMaxHight {
  from {
    height: 0;
    opacity: 0;
  }
  to {
    height: 60px; /* your line height here */
    opacity: 1;
  }
}

#cardForm .StripeElement {
  width: 100%;
  background: #fff;
  padding: 8px 5px;
}
#mapView .gm-style {
  background: #151515;
}
#mapView .gm-style .gm-style-iw {
  display: flex;
  align-items: flex-start;
  max-width: 300px !important;
  font-size: 13px;
  line-height: 18px;
  background: #282a2d;
  border: 1px solid #000;
  padding: 7px 10px !important;
}
#mapView .gm-style .gm-style-iw-c .infowindowstyle {
  /* width: 92%; */
  margin: 0 10px 0 0;
}
.gm-style .gm-style-iw-tc{
  -webkit-filter: none;
          filter: none;
}
.gm-style .gm-style-iw-t::after,.gm-style .gm-style-iw-tc::after {
  background: #282a2d;
  box-shadow: none;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  /* top: -2px; */
}
#mapView .gm-style .gm-style-iw-c .gm-ui-hover-effect {
  top: -1px !important;
  right: 0px !important;
  position: relative !important;
  border-radius: 20px;
  background: #000 !important;
  margin-left: 10px !important;
  width: 25px !important;
  height: 25px !important;
}
#mapView .gm-ui-hover-effect > span {
  background-color: #bbb;
  margin: 5px !important;
  text-align: center !important;
}

#mapView .gm-style .gm-style-iw-d {
  overflow: auto !important;
}
#mapView .gm-style .gm-style-iw-d h4 {
  color: #aaa;
  margin: 2px 0 !important;
}
#mapView .gm-style .gm-style-iw-d p {
  color: #777;
}
#mapView .gm-style-cc div {
  /* display: none; */
  background: #444;
}

#mapView .gm-style a img {
  opacity: 0.2;
}

button {
  background: var(--color-surface);
  color: var(--color-primary);
  border-color: var(--color-primary);
  padding: 8px 16px;
  font-size: 1.2rem;
  border-radius: 4px;
}
.form-control {
  margin-bottom: 5px;
  padding: 4px 15px;
}
form label {
  font-size: 0.9rem;
  padding: 2px 0 5px;
  display: block;
  color: #616161;
  margin-top: 4px;
}
form input[type="text"],
form input[type="email"],
form input[type="number"],
form input[type="password"] {
  padding: 0.6em;
  font-weight: 500;
  border-radius: 0px;
  /* width: fit-content; */
  /* width: -webkit-fill-available; */
  width: 100%;
  box-sizing: border-box;
  box-shadow: none;
  outline: 0;
  border: 1px solid #e2e2e2;
  color: #555;
  font-size: 0.95rem;
  margin: 2px 0;
  height: 36px;
}
form input.noBorder {
  border: 0px;
  border-bottom: 1px solid #d2d2d2;
  padding: 0.5rem;
}
form input.noMargin {
  margin: 0px;
}
form .error {
  color: #f23436;
  padding: 3px 0;
  font-size: 14px;
}

form .arrayFields input {
  width: 80%;
  margin: 5px;
}
form textarea {
  padding: 0.6em;
  font-weight: 500;
  border-radius: 0px;
  /* width: fit-content; */
  width: -webkit-fill-available;
  box-shadow: none;
  outline: 0;
  border: 1px solid #ccc;
  color: #555;
  font-size: 0.95rem;
  margin: 2px 0;
}
form input::-webkit-input-placeholder, form textarea::-webkit-input-placeholder {
  color: #ccc;
}
form input:-ms-input-placeholder, form textarea:-ms-input-placeholder {
  color: #ccc;
}
form input::-ms-input-placeholder, form textarea::-ms-input-placeholder {
  color: #ccc;
}
form input::placeholder,
form textarea::placeholder {
  color: #ccc;
}

form select {
  border-radius: 0px;
  border: 1px solid #d7d7d7;
  box-shadow: none;
  color: #555;
  background-color: #fff;
  width: 100%;
  height: 38px;
  padding: 6px 8px;
  font-size: 14px;
  outline: 0;
}

body.ReactModal__Body--open {
  overflow: hidden;
}

.modal {
  position: fixed;
  box-sizing: border-box;
  max-width: 100vw;
  overflow: hidden;
  padding: 5px 0 0;
  background: #fff;
  outline: none;
  border-radius: 5px;
  max-height: 100vh;
  /* opacity: 0; */
}
.modal .header-logo {
  width: auto;
  max-height: 35px;
  max-width: 100%;
  margin: 15px 0 15px 30px;
}

.ReactModal__Content .modal-header {
  padding: 15px 25px;
  text-transform: capitalize;
  border-bottom: 5px solid #f4f5f9;
  display: flex;
  font-size: 1.1em;
  font-weight: 550;
  align-items: center; 
}

.ReactModal__Content .modal-header .backIcon {
  cursor: pointer;
  font-size: 1.3rem;
  padding-right: 10px;
}

.ReactModal__Content .modal-body {
  padding: 15px;
  max-height: calc(100vh - 90px);
  overflow: overlay;
}

/*===== MODAL OVERLAY STYLES=========*/
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
  /* width: 100vw;
  height: 100vh;  */
  background-color: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
}
.ReactModal__Overlay {
  opacity: 0;
  /* transition: opacity 500ms ease-in-out; */
}
.ReactModal__Overlay--after-open {
  opacity: 1;
}
.ReactModal__Overlay--before-close {
  opacity: 0;
}

/*===== MODAL CENTER STYLES=========*/
.modalCentered {
  right: auto;
  left: 50%;
  top: 50%;
  bottom: auto;
  margin-right: -50%;
  height: auto;
}
.ReactModal__Content.modalCentered {
  opacity: 0;
  -webkit-transform: translate(50%, 50%);
          transform: translate(50%, 50%);
  transition: opacity 0.2s ease-in-out;
  /* transition: all .3s ease-in-out; */
  /* transition:opacity  linear 0.15s */
}
.modalCentered.ReactModal__Content--after-open {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  /* transform: translate(0,0); */
}
.modalCentered.ReactModal__Content--before-close {
  opacity: 0;
  -webkit-transform: translateX(50%, 50%);
          transform: translateX(50%, 50%);
  /* transform: translate(-50%,-50); */
}

/*===== MODAL RIGHT STYLES=========*/
.modalRight {
  right: 0;
  top: 0;
  left: auto;
  bottom: 0;
  height: 100vh;
  border-radius: 0px !important;
}
.modalRight h3 {
  font-size: 20px;
  margin: 20px 15px;
}
.ReactModal__Content.modalRight {
  opacity: 0;
  -webkit-transform: translate(25%, 0);
          transform: translate(25%, 0);
  /* transition: all 500ms ease-in-out; */
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.modalRight.ReactModal__Content--after-open {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.modalRight.ReactModal__Content--before-close {
  opacity: 0;
  -webkit-transform: translate(25%, 0);
          transform: translate(25%, 0);
}
.ReactModal__Content.modalRight .modal-body {
  height: calc(100vh - 70px);
  overflow: overlay;
}

/*===== MODAL BOTTOM STYLES=========*/
.modalBottom {
  bottom: 0;
  width: 100vw;
  border-radius: 0px !important;
}
.ReactModal__Content.modalBottom {
  opacity: 0;
  -webkit-transform: translate(0, 25%);
          transform: translate(0, 25%);
  /* transition: all 500ms ease-in-out; */
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}
.modalBottom.ReactModal__Content--after-open {
  opacity: 1;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.modalBottom.ReactModal__Content--before-close {
  opacity: 0;
  -webkit-transform: translate(0, 25%);
          transform: translate(0, 25%);
}

.highcharts-title, .highcharts-subtitle {
  text-transform: uppercase;
}

.highcharts-tooltip text {
  fill: #F0F0F0;
}

.highcharts-range-selector-buttons text {
  fill: silver;
}

.highcharts-yaxis-grid {
  stroke-width: 1px;
}

.highcharts-axis-labels, .highcharts-axis-title {
  fill: #E0E0E3;
}

.highcharts-navigator .highcharts-navigator-handle {
  fill: #666;
  stroke: #aaa;
}

.highcharts-navigator .highcharts-navigator-outline {
  stroke: #CCC;
}

.highcharts-navigator .highcharts-navigator-xaxis .highcharts-grid-line {
  stroke: #e2e2e2;
}

.highcharts-scrollbar .highcharts-scrollbar-rifles {
  stroke: #fff;
}

.highcharts-scrollbar .highcharts-scrollbar-button {
  stroke: #606063;
  fill: #606063;
}

.highcharts-scrollbar .highcharts-scrollbar-arrow {
  fill: #CCC;
}

.highcharts-scrollbar .highcharts-scrollbar-thumb {
  fill: #808083;
  stroke: #808083;
}

.highcharts-contextbutton .highcharts-button-symbol {
  stroke: #DDDDDD;
}

/**
 * @license Highcharts
 *
 * (c) 2009-2016 Torstein Honsi
 *
 * License: www.highcharts.com/license
 */
.highcharts-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  text-align: left;
  line-height: normal;
  z-index: 0;
  /* #1072 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-family: "Unica One", Arial, Helvetica, sans-serif;
  font-size: 12px;
}

.highcharts-root {
  display: block;
}

.highcharts-root text {
  stroke-width: 0;
}

.highcharts-strong {
  font-weight: bold;
}

.highcharts-emphasized {
  font-style: italic;
}

.highcharts-anchor {
  cursor: pointer;
}

.highcharts-background {
  fill: transparent;
}

.highcharts-plot-border, .highcharts-plot-background {
  fill: none;
}

.highcharts-label-box {
  fill: none;
}

.highcharts-button-box {
  fill: inherit;
}

.highcharts-tracker-line {
  stroke-linejoin: round;
  stroke: rgba(192, 192, 192, 0.0001);
  stroke-width: 22;
  fill: none;
}

.highcharts-tracker-area {
  fill: rgba(192, 192, 192, 0.0001);
  stroke-width: 0;
}

/* Titles */
.highcharts-title {
  fill: #E0E0E3;
  font-size: 20px;
}

.highcharts-subtitle {
  fill: #E0E0E3;
}

/* Axes */
.highcharts-axis-line {
  fill: none;
  stroke: #e2e2e2;
}

.highcharts-yaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-axis-title {
  fill: #E0E0E3;
}

.highcharts-axis-labels {
  fill: #E0E0E3;
  cursor: default;
  font-size: 0.9em;
}

.highcharts-grid-line {
  fill: none;
  stroke: #e2e2e2;
}

.highcharts-xaxis-grid .highcharts-grid-line {
  stroke-width: 0px;
}

.highcharts-tick {
  stroke: #707073;
}

.highcharts-yaxis .highcharts-tick {
  stroke-width: 0;
}

.highcharts-minor-grid-line {
  stroke: #505053;
}

.highcharts-crosshair-thin {
  stroke-width: 1px;
  stroke: #606063;
}

.highcharts-crosshair-category {
  stroke: #707073;
  stroke-opacity: 0.25;
}

/* Credits */
.highcharts-credits {
  cursor: pointer;
  fill: #666;
  font-size: 0.7em;
  transition: fill 250ms, font-size 250ms;
}

.highcharts-credits:hover {
  fill: black;
  font-size: 1em;
}

/* Tooltip */
.highcharts-tooltip {
  cursor: default;
  pointer-events: none;
  white-space: nowrap;
  transition: stroke 150ms;
}

.highcharts-tooltip text {
  color: #777;
  fill: #e2e2e2!important;
}

.highcharts-tooltip .highcharts-header {
  font-size: 0.85em;
}

.highcharts-tooltip-box {
  stroke-width: 1px;
  fill: #1c1b20;
  fill-opacity: 0.85;
  border: 0px;
}

.highcharts-tooltip-box .highcharts-label-box {
  fill: rgba(0, 0, 0, 0.85);
  fill-opacity: 0.85;
}

.highcharts-selection-marker {
  fill: #335cad;
  fill-opacity: 0.25;
}

.highcharts-graph {
  fill: none;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.highcharts-state-hover .highcharts-graph {
  stroke-width: 3;
}

.highcharts-state-hover path {
  transition: stroke-width 50;
  /* quick in */
}

.highcharts-state-normal path {
  transition: stroke-width 250ms;
  /* slow out */
}

/* Legend hover affects points and series */
g.highcharts-series,
.highcharts-point,
.highcharts-markers,
.highcharts-data-labels {
  transition: opacity 250ms;
}

.highcharts-legend-series-active g.highcharts-series:not(.highcharts-series-hover),
.highcharts-legend-point-active .highcharts-point:not(.highcharts-point-hover),
.highcharts-legend-series-active .highcharts-markers:not(.highcharts-series-hover),
.highcharts-legend-series-active .highcharts-data-labels:not(.highcharts-series-hover) {
  opacity: 0.2;
}

/* Series options */
/* Default colors */
.highcharts-color-0 {
  fill: #2b908f;
  stroke: #2b908f;
}

.highcharts-color-1 {
  fill: #90ee7e;
  stroke: #90ee7e;
}

.highcharts-color-2 {
  fill: #f45b5b;
  stroke: #f45b5b;
}

.highcharts-color-3 {
  fill: #7798BF;
  stroke: #7798BF;
}

.highcharts-color-4 {
  fill: #aaeeee;
  stroke: #aaeeee;
}

.highcharts-color-5 {
  fill: #ff0066;
  stroke: #ff0066;
}

.highcharts-color-6 {
  fill: #eeaaee;
  stroke: #eeaaee;
}

.highcharts-color-7 {
  fill: #55BF3B;
  stroke: #55BF3B;
}

.highcharts-color-8 {
  fill: #DF5353;
  stroke: #DF5353;
}

.highcharts-color-9 {
  fill: #7798BF;
  stroke: #7798BF;
}

.highcharts-color-10 {
  fill: #aaeeee;
  stroke: #aaeeee;
}

.highcharts-area {
  fill-opacity: 0.75;
  stroke-width: 0;
}

.highcharts-markers {
  stroke-width: 1px;
  /* stroke: #2a2a2b; */
}

.highcharts-point {
  stroke-width: 1px;
}

.highcharts-dense-data .highcharts-point {
  stroke-width: 0;
}

.highcharts-data-label {
  font-size: 0.9em;
  font-weight: bold;
}

.highcharts-data-label-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-data-label text, text.highcharts-data-label {
  fill: #B0B0B3;
}

.highcharts-data-label-connector {
  fill: none;
}

.highcharts-halo {
  fill-opacity: 0.25;
  stroke-width: 0;
}

.highcharts-series:not(.highcharts-pie-series) .highcharts-point-select,
.highcharts-markers .highcharts-point-select {
  fill: #606063;
  stroke: #fff;
}

.highcharts-column-series rect.highcharts-point {
  stroke: #2a2a2b;
}

.highcharts-column-series .highcharts-point {
  transition: fill-opacity 250ms;
}

.highcharts-column-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pie-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #2a2a2b;
}

.highcharts-pie-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #2a2a2b;
}

.highcharts-funnel-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-funnel-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-pyramid-series .highcharts-point {
  stroke-linejoin: round;
  stroke: #2a2a2b;
}

.highcharts-pyramid-series .highcharts-point-hover {
  fill-opacity: 0.75;
  transition: fill-opacity 50ms;
}

.highcharts-pyramid-series .highcharts-point-select {
  fill: inherit;
  stroke: inherit;
}

.highcharts-solidgauge-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-treemap-series .highcharts-point {
  stroke-width: 1px;
  stroke: #707073;
  transition: stroke 250ms, fill 250ms, fill-opacity 250ms;
}

.highcharts-treemap-series .highcharts-point-hover {
  stroke: #666;
  transition: stroke 25ms, fill 25ms, fill-opacity 25ms;
}

.highcharts-treemap-series .highcharts-above-level {
  display: none;
}

.highcharts-treemap-series .highcharts-internal-node {
  fill: none;
}

.highcharts-treemap-series .highcharts-internal-node-interactive {
  fill-opacity: 0.15;
  cursor: pointer;
}

.highcharts-treemap-series .highcharts-internal-node-interactive:hover {
  fill-opacity: 0.75;
}

/* Legend */
.highcharts-legend-box {
  fill: none;
  stroke-width: 0;
}

.highcharts-legend-item > text {
  fill: #E0E0E3;
  font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  stroke-width: 0;
}

.highcharts-legend-item:hover text {
  fill: #fff;
}

.highcharts-legend-item-hidden * {
  fill: #606063 !important;
  stroke: #606063 !important;
  transition: fill 250ms;
}

.highcharts-legend-nav-active {
  fill: #F0F0F3;
  cursor: pointer;
}

.highcharts-legend-nav-inactive {
  fill: #606063;
}

.highcharts-legend-title-box {
  fill: none;
  stroke-width: 0;
}

/* Bubble legend */
.highcharts-bubble-legend-symbol {
  stroke-width: 2;
  fill-opacity: 0.5;
}

.highcharts-bubble-legend-connectors {
  stroke-width: 1;
}

.highcharts-bubble-legend-labels {
  fill: #E0E0E3;
}

/* Loading */
.highcharts-loading {
  position: absolute;
  background-color: #2a2a2b;
  opacity: 0.5;
  text-align: center;
  z-index: 10;
  transition: opacity 250ms;
}

.highcharts-loading-hidden {
  height: 0 !important;
  opacity: 0;
  overflow: hidden;
  transition: opacity 250ms, height 250ms step-end;
}

.highcharts-loading-inner {
  font-weight: bold;
  position: relative;
  top: 45%;
}

/* Plot bands and polar pane backgrounds */
.highcharts-plot-band, .highcharts-pane {
  fill: #fff;
  fill-opacity: 0.05;
}

.highcharts-plot-line {
  fill: none;
  stroke: #666;
  stroke-width: 1px;
}

/* Highcharts More and modules */
.highcharts-boxplot-box {
  fill: #2a2a2b;
}

.highcharts-boxplot-median {
  stroke-width: 2px;
}

.highcharts-bubble-series .highcharts-point {
  fill-opacity: 0.5;
}

.highcharts-errorbar-series .highcharts-point {
  stroke: #fff;
}

.highcharts-gauge-series .highcharts-data-label-box {
  stroke: #606063;
  stroke-width: 1px;
}

.highcharts-gauge-series .highcharts-dial {
  fill: #fff;
  stroke-width: 0;
}

.highcharts-polygon-series .highcharts-graph {
  fill: inherit;
  stroke-width: 0;
}

.highcharts-waterfall-series .highcharts-graph {
  stroke: #E0E0E3;
  stroke-dasharray: 1, 3;
}

.highcharts-sankey-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-sankey-series .highcharts-link {
  transition: fill 250ms, fill-opacity 250ms;
  fill-opacity: 0.5;
}

.highcharts-sankey-series .highcharts-point-hover.highcharts-link {
  transition: fill 50ms, fill-opacity 50ms;
  fill-opacity: 1;
}

.highcharts-venn-series .highcharts-point {
  fill-opacity: 0.75;
  stroke: #606063;
  transition: stroke 250ms, fill-opacity 250ms;
}

.highcharts-venn-series .highcharts-point-hover {
  fill-opacity: 1;
  stroke: #606063;
}

/* Highstock */
.highcharts-navigator-mask-outside {
  fill-opacity: 0;
}

.highcharts-navigator-mask-inside {
  fill: rgba(255, 255, 255, 0.1);
  /* navigator.maskFill option */
  fill-opacity: 0.25;
  cursor: ew-resize;
}

.highcharts-navigator-outline {
  stroke: #606063;
  fill: none;
}

.highcharts-navigator-handle {
  stroke: #606063;
  fill: #505053;
  cursor: ew-resize;
}

.highcharts-navigator-series {
  fill: #7798BF;
  stroke: #A6C7ED;
}

.highcharts-navigator-series .highcharts-graph {
  stroke-width: 1px;
}

.highcharts-navigator-series .highcharts-area {
  fill-opacity: 0.05;
}

.highcharts-navigator-xaxis .highcharts-axis-line {
  stroke-width: 0;
}

.highcharts-navigator-xaxis .highcharts-grid-line {
  stroke-width: 1px;
  stroke: #707073;
}

.highcharts-navigator-xaxis.highcharts-axis-labels {
  fill: #666;
}

.highcharts-navigator-yaxis .highcharts-grid-line {
  stroke-width: 0;
}

.highcharts-scrollbar-thumb {
  fill: #606063;
  stroke: #606063;
  stroke-width: 1px;
}

.highcharts-scrollbar-button {
  fill: #707073;
  stroke: #606063;
  stroke-width: 1px;
}

.highcharts-scrollbar-arrow {
  fill: #E0E0E3;
}

.highcharts-scrollbar-rifles {
  stroke: #E0E0E3;
  stroke-width: 1px;
}

.highcharts-scrollbar-track {
  fill: #404043;
  stroke: #404043;
  stroke-width: 1px;
}

.highcharts-button {
  fill: #505053;
  stroke: #606063;
  cursor: default;
  stroke-width: 1px;
  transition: fill 250ms;
}

.highcharts-button text {
  fill: #ccc;
}

.highcharts-button-hover {
  transition: fill 0ms;
  fill: #707073;
  stroke: #606063;
}

.highcharts-button-hover text {
  fill: #fff;
}

.highcharts-button-pressed {
  font-weight: bold;
  fill: #000003;
  stroke: #606063;
}

.highcharts-button-pressed text {
  fill: #fff;
  font-weight: bold;
}

.highcharts-button-disabled text {
  fill: #ccc;
}

.highcharts-range-selector-buttons .highcharts-button {
  stroke-width: 0px;
}

.highcharts-range-label rect {
  fill: none;
}

.highcharts-range-label text {
  fill: #E0E0E3;
}

.highcharts-range-input rect {
  fill: none;
}

.highcharts-range-input text {
  fill: silver;
}

.highcharts-range-input {
  stroke-width: 1px;
  stroke: #505053;
}

input.highcharts-range-selector {
  position: absolute;
  border: 0;
  width: 1px;
  /* Chrome needs a pixel to see it */
  height: 1px;
  padding: 0;
  text-align: center;
  left: -9em;
  /* #4798 */
}

.highcharts-crosshair-label text {
  fill: #2a2a2b;
  font-size: 1.1em;
}

.highcharts-crosshair-label .highcharts-label-box {
  fill: inherit;
}

.highcharts-candlestick-series .highcharts-point {
  stroke: #fff;
  stroke-width: 1px;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: #2a2a2b;
}

.highcharts-ohlc-series .highcharts-point-hover {
  stroke-width: 3px;
}

.highcharts-flags-series .highcharts-point .highcharts-label-box {
  stroke: #666;
  fill: #2a2a2b;
  transition: fill 250ms;
}

.highcharts-flags-series .highcharts-point-hover .highcharts-label-box {
  stroke: #fff;
  fill: #707073;
}

.highcharts-flags-series .highcharts-point text {
  fill: #fff;
  font-size: 0.9em;
  font-weight: bold;
}

/* Highmaps */
.highcharts-map-series .highcharts-point {
  transition: fill 500ms, fill-opacity 500ms, stroke-width 250ms;
  stroke: #606063;
}

.highcharts-map-series .highcharts-point-hover {
  transition: fill 0ms, fill-opacity 0ms;
  fill-opacity: 0.5;
  stroke-width: 2px;
}

.highcharts-mapline-series .highcharts-point {
  fill: none;
}

.highcharts-heatmap-series .highcharts-point {
  stroke-width: 0;
}

.highcharts-map-navigation {
  font-size: 1.3em;
  font-weight: bold;
  text-align: center;
}

.highcharts-coloraxis {
  stroke-width: 0;
}

.highcharts-coloraxis-marker {
  fill: #666;
}

.highcharts-null-point {
  fill: #f7f7f7;
}

/* 3d charts */
.highcharts-3d-frame {
  fill: transparent;
}

/* Exporting module */
.highcharts-contextbutton {
  fill: #505053;
  /* needed to capture hover */
  stroke: none;
  stroke-linecap: round;
}

.highcharts-contextbutton:hover {
  fill: #707073;
  stroke: #707073;
}

.highcharts-button-symbol {
  stroke: #E0E0E3;
  stroke-width: 3px;
}

.highcharts-menu {
  border: 1px solid #666;
  background: #2a2a2b;
  padding: 5px 0;
  box-shadow: 3px 3px 10px #888;
}

.highcharts-menu-item {
  padding: 0.5em 1em;
  background: none;
  color: #E0E0E3;
  cursor: pointer;
  transition: background 250ms, color 250ms;
}

.highcharts-menu-item:hover {
  background: #335cad;
  color: #2a2a2b;
}

/* Drilldown module */
.highcharts-drilldown-point {
  cursor: pointer;
}

.highcharts-drilldown-data-label text,
text.highcharts-drilldown-data-label,
.highcharts-drilldown-axis-label {
  cursor: pointer;
  fill: #F0F0F3;
  font-weight: bold;
  text-decoration: underline;
}

/* No-data module */
.highcharts-no-data text {
  font-weight: bold;
  font-size: 12px;
  fill: #E0E0E3;
}

/* Drag-panes module */
.highcharts-axis-resizer {
  cursor: ns-resize;
  stroke: black;
  stroke-width: 2px;
}

/* Bullet type series */
.highcharts-bullet-target {
  stroke-width: 0;
}

/* Lineargauge type series */
.highcharts-lineargauge-target {
  stroke-width: 1px;
  stroke: #E0E0E3;
}

.highcharts-lineargauge-target-line {
  stroke-width: 1px;
  stroke: #E0E0E3;
}

/* Annotations module */
.highcharts-annotation-label-box {
  stroke-width: 1px;
  stroke: #fff;
  fill: #fff;
  fill-opacity: 0.75;
}

.highcharts-annotation-label text {
  fill: #707073;
}

/* Gantt */
.highcharts-treegrid-node-collapsed, .highcharts-treegrid-node-expanded {
  cursor: pointer;
}

.highcharts-point-connecting-path {
  fill: none;
}

.highcharts-grid-axis .highcharts-tick {
  stroke-width: 1px;
}

.highcharts-grid-axis .highcharts-axis-line {
  stroke-width: 1px;
}
#dashboard .MuiSvgIcon-fontSizeLarge{
    /* float: right; */
    font-size: 1.2875rem;
    color: #f3f2f8;
    background: #3f97ff;
    padding: 5px;
    border-radius: 5px; 
    margin-top: 4px;
}
#dashboard small{
    font-size: 0.9rem;
    font-weight: 400;
} 
#dashboard .MuiTableCell-root{
    padding: 12px 9px;
}
:root {
  --grey: #212529;
  --white: #fff;
  --border: 10px solid;
}

.tooltipContainer {
  position: relative;
  /* display: inline-flex; */
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
  margin: 0 10px;
  -webkit-tap-highlight-color: transparent;
}
.tooltipContainer svg {
  color: #b2b2b2;
}
.tooltipContainer svg:hover {
  color: #000;
}
.tooltipContent {
  color: #ffffff;
  text-align: center;
  position: absolute;
  z-index: 10;
  padding: 10px;
  font-size: 12px;
  cursor: default;
  border-radius: 3px;
  /* white-space: nowrap; */
  min-width: 190px;
  font-family: inherit;
  line-height: 20px;
  background-color: #212529;
  background-color: var(--grey);
  box-shadow: 0 0 0.3rem rgba(0, 0, 0, 0.16);
  -webkit-animation: fadeIn ease-in-out 0.5s;
          animation: fadeIn ease-in-out 0.5s;
  line-break: auto;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
}
.tooltipContent.sm {
  max-width: 23rem;
}
.tooltipContent.md {
  max-width: 40rem;
}
.tooltipContent.white {
  background-color: #fff;
  color: #333;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  left: 110%;
}
.arrow {
  position: absolute;
  width: 0;
  height: 0;
}

.top {
  bottom: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.top .arrow {
  bottom: -8px;
  left: calc(50% - 10px);
  border-right: 10px solid transparent;
  border-right: var(--border) transparent;
  border-top: 10px solid #212529;
  border-top: var(--border) var(--grey);
  border-left: 10px solid transparent;
  border-left: var(--border) transparent;
}
.tooltipContent.white .arrow {
  content: "";
  width: 12px;
  height: 12px;
  background: #fff;
  border-left: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  border-top: 0px;
  border-right: 0px;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
          transform: translate(-50%, -50%) rotate(-45deg);
  bottom: -14px;
}

.right,
.left {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.right .arrow,
.left .arrow {
  top: calc(50% - 10px);
  border-top: 10px solid transparent;
  border-top: var(--border) transparent;
  border-bottom: 10px solid transparent;
  border-bottom: var(--border) transparent;
}

.right {
  left: calc(100% + 18px);
}
.right .arrow {
  left: -8px;
  border-right: 10px solid #212529;
  border-right: var(--border) var(--grey);
}

.left {
  right: calc(100% + 18px);
}
.left .arrow {
  right: -8px;
  border-left: 10px solid #212529;
  border-left: var(--border) var(--grey);
}

.bottom {
  top: calc(100% + 10px);
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.bottom .arrow {
  top: -8px;
  left: calc(50% - 10px);
  border-right: 10px solid transparent;
  border-right: var(--border) transparent;
  border-bottom: 10px solid #212529;
  border-bottom: var(--border) var(--grey);
  border-left: 10px solid transparent;
  border-left: var(--border) transparent;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: block;
  margin: 0px;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 37px;
  height: 20px;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #e2e2e2;
  position: relative;
  transition: background-color 0.2s;
  padding: 0px;
}

.react-switch-label .react-switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 45px;
  transition: 0.2s;
  background: #e2e2e2;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  background: #EBAE25;
}

.react-switch-label:active .react-switch-button {
  width: 33px;
}

.switchClassTitle {
  display: inline-block;
  max-width: 75%;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 18px;
  padding: 0;
}
.toggle-switch input[type="checkbox"] {
  display: none;
}
.toggle-switch .switch {
  position: absolute;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 25px;
  top: 0;
  right: 0;
  bottom: 0;
  width: 45px;
  height: 22px;
  left: 0;
  transition: background-color 0.2s ease;
}
.toggle-switch .switch::before {
  position: absolute;
  content: "";
  left: 1px;
  top: 2px;
  width: 18px;
  height: 18px;
  background-color: #e2e2e2;
  border-radius: 50%;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.toggle-switch input[type="checkbox"]:checked + .switch::before {
  -webkit-transform: translateX(25px);
          transform: translateX(25px);
  background-color: #EBAE25;
}
.toggle-switch input[type="checkbox"]:checked + .switch {
  background-color: #fff;
  border: 1px solid #e2e2e2;
}

.invoiceDiv {
  width: 80%;
  margin: 0 auto;
  padding: 30px 0;
}
.invoiceDiv .flex {
  display: inline-block;
  box-sizing: border-box;
  vertical-align: top;
}
.invoiceDiv img {
  width: 170px;
  padding: 0 0 10px;
}
.invoiceDiv label {
  display: block;
  font-weight: 450;
  margin: 10px 0 5px;
  line-height: 22px;
}

.invoiceDiv p {
  padding: 0;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  color: #333;
}

.invoiceDiv table,
.thermalInvoiceDiv table {
  display: table;
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}
.invoiceDiv table {
  margin: 10px 0;
}
.thermalInvoiceDiv table {
  margin: 30px 0;
}
.invoiceDiv table thead th {
  border: 0.5px solid black;
  border-bottom: 0px;
  font-weight: 600;
  font-size: 14px;
  padding: 17px 20px;
  text-align: left;
}

.invoiceDiv table tr td {
  border: 0.5px solid black;
  font-size: 14px;
  padding: 15px 20px;
  vertical-align: middle;
  text-align: left;
}
.invoiceDiv table tr.payment td {
  border: 0px;
  padding: 10px 5px;
}
.invoiceDiv table tr td:last-child {
  text-align: right;
}
/* .invoiceDiv table tr.payment td:last-child {
  border-right: 0.5px solid black;
}
.invoiceDiv table tr.payment td:nth-last-child(2) {
  border-left: 0.5px solid black;
} */
.invoiceDiv table tr:last-child td:last-child,
.invoiceDiv table tr:last-child td:nth-last-child(2) {
  /* border-bottom: 0.5px solid black; */
  padding: 15px 5px;
  font-weight: 450;
  border-top: 0.5px solid black;
}

.invoiceDiv table tr.payment.line td {
  /* display: none; */
  visibility: hidden;
  padding: 0;
  line-height: 0;
}
.invoiceDiv table tr.line td:last-child,
.invoiceDiv table tr.line td:nth-last-child(2) {
  border-top: 0.5px solid #000;
}
.invoiceDiv table tr.nopadding td {
  padding: 5px 15px;
}
.invoiceDiv table tr.noheight td {
  line-height: 0px;
}

.thermalInvoiceDiv {
  padding: 10px 15px;
}
.thermalInvoiceDiv table {
  margin: 15px 0;
}
.thermalInvoiceDiv hr {
  margin: 12px 0;
}
.thermalInvoiceDiv img {
  display: block;
  margin: 0 auto;
  padding: 15px 0;
  width: 150px;
}
.thermalInvoiceDiv h4 {
  margin: 10px 0 5px;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}
.thermalInvoiceDiv p {
  padding: 0;
  margin: 0;
  font-size: 15.5px;
  line-height: 25px;
  color: #333;
}
.thermalInvoiceDiv table tr td {
  padding: 8px;
  vertical-align: top;
  font-size: 15px;
  line-height: 22px;
}
.thermalInvoiceDiv table tr.payment td {
  padding: 4px 8px;
}
.thermalInvoiceDiv table tr.payment:last-child td {
  font-weight: 450;
}

.star-rating {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.star {
  /* cursor: pointer; */
  width: 1em;
  height: 1em;
  margin: 2px;
  background-color: #d7d7d7;
  -webkit-clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
  clip-path: polygon(
    50% 0%,
    63% 38%,
    100% 38%,
    69% 59%,
    82% 100%,
    50% 75%,
    18% 100%,
    31% 59%,
    0% 38%,
    37% 38%
  );
}

.star.selected {
  background-color: #fbad00;
}

.DraftEditor-editorContainer {
  height: auto;
}
.DraftEditor-root {
  height: 40vh;
  border: 1px solid #f2f2f2;
  overflow: auto;
  padding: 15px;
}
.rdw-image-modal-btn,.rdw-link-modal-btn{
  padding:0;font-size: 15px;
}
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #d1d1d1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #b1b1b1;
}

.react-tags__selected {
  display: inline;
}

.react-tags .react-tags-list .react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 12px;
  border-radius: 20px;
  background: #EBAE25;
  color: #fff;

  /* match the font styles */
  font-size: 14px;
  line-height: inherit;
}

/* .react-tags__selected-tag:after {
  content: "\2715";
  color: #aaa;
  margin-left: 8px;
} */

.react-tags .react-tags-list {
  margin: 0;
  padding: 0;
  display: inline;
}

.react-tags .react-tags-list .react-tags__selected-tag:hover,
.react-tags .react-tags-list .react-tags__selected-tag:focus {
  border-color: #b1b1b1;
}

.react-tags__selected-tag svg {
  font-size: 13px;
  margin-left: 5px;
  vertical-align: top;
  padding-top: 2px;
  cursor: pointer;
}

.react-tags .react-tags-input {
  position: relative;
  border-style: none;
  /* border: none; */
  display: inline-block;
  flex: 1 1 auto;
  margin: 5px;
  min-height: 100%;
  font-size: 12pt;
  outline: 0;
  width: auto;
  padding: 0;
  height: auto;
}
.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {
  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }
}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}
.react-tags-suggestions {
  position: relative;
}
.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 9;
}

@media screen and (min-width: 30em) {
  .react-tags__suggestions {
    width: 100%;
  }
}

.react-tags-suggestions .react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  overflow: overlay;
  max-height: 135px;
  background: white;
  border: 1px solid #d1d1d1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions ul li.react-tags__selected-tag {
  border-bottom: 1px solid #ddd;
  padding: 9px 8px;
  background: transparent;
  display: block;
  border-radius: 0px;
  color: #000;
  margin: 0px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions ul li.react-tags__selected-tag:hover {
  cursor: pointer;
  background: #EBAE25;
  color: #fff;
  border-radius: 0px;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}

.Sidebar {
  width: 18%;
  min-width: 165px;
  background: #1c1b20;
  height: 100vh;
  overflow: overlay;
  overflow: auto;
  border-right: 1px solid #444;
}
.App-header {
  display: inline-flex;
  /* width:100%;  */
  padding: 22px 17px;align-items: center;
}
.App-header img {
  width: 38px;
  padding: 2px;
  height: 38px;
  background: #fff;
  margin-right: 10px;
  border-radius: 50%;
}
.App-header h3 {
  margin: 2px 0 4px;
  font-weight: 550;
  color: #fff;
  font-size: 17.5px;
}
.App-header p {
  color: #ccc;
  margin: 0px;
  font-size: 15px;
}
.Sidebar h4 {
  color: #616161;
  font-weight: 500;
  padding: 0 20px;
  margin: 15px 0;
}
.Sidebar ul {
  list-style-type: none;
  margin: 0 0 15px;
  padding: 0 10px;
}
.Sidebar ul li {
  display: flex;
  margin: 2px 0;
  padding: 6px 15px;
}
.Sidebar ul li a {
  color: #bbb;
  font-size: 14px;
  padding: 2px 0 0 0;
  width: 90%;
  display: inline-block;
  text-decoration: none;
  font-weight: 550;
  letter-spacing: -0.2px;
}
.Sidebar ul li.active,
.Sidebar ul li.active:hover {
  background: #EBAE25;
  border-radius: 5px;
}
.Sidebar ul li.active a {
  color: #fff;
}
.Sidebar ul li.active a .icon img {
  opacity: 1;
}
.Sidebar ul li a .icon {
  margin-right: 15px;
  vertical-align: middle;
}
.Sidebar ul li a .icon img {
  opacity: 0.7;
}
.Sidebar ul .icon {
  margin-right: 10px;
}
.Sidebar ul li:hover {
  background: #3a393e;
  border-radius: 4px;
}
.Sidebar .MuiSvgIcon-fontSizeSmall {
  color: #777779;
  margin-right: 10px;
  font-size: 1.2rem;
  vertical-align: bottom;
}

.Sidebar hr {
  border: 0px;
  border-bottom: 1px solid #444;
}

.Sidebar-footer {
  position: fixed;
  width: 18%;
  min-width: 165px;
  bottom: 0px;
}
.Sidebar-footer p {
  text-align: center;
  color: #616161;
  font-size: 14.5px;
}
.Sidebar span .MuiSvgIcon-root {
  float: right;
  color: #888888;
  font-size: 1.3rem;
  padding: 9px 5px 7px 0;
}

.Sidebar .MuiTreeView-root {
  padding: 7px 8px 9px;
  width: 90%;
  margin: 0;
  display: inherit;
}
.Sidebar .MuiTreeItem-root {
  display: block;
  width: 100%;
  margin: 0;
}
.Sidebar .MuiTreeItem-iconContainer {
  width: 0px;
  margin-right: 0px;
}
.Sidebar .MuiTreeItem-label {
  color: #dddddd;
}
.Sidebar
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label,
.Sidebar
  .MuiTreeItem-root.Mui-selected
  > .MuiTreeItem-content
  .MuiTreeItem-label:hover,
.Sidebar
  .MuiTreeItem-root.Mui-selected:focus
  > .MuiTreeItem-content
  .MuiTreeItem-label {
  background-color: transparent;
  color: #fff;
}
.Sidebar .MuiTreeItem-group {
  margin: 0;
  padding: 0;
}
.Sidebar .MuiCollapse-wrapperInner a {
  padding: 5px 0;
}
.Sidebar .MuiCollapse-wrapperInner a:last-child {
  padding-bottom: 0px;
}

header {
  background-color: rgb(21, 21, 21);
  /* box-shadow: rgb(36, 36, 36) 0px -1px 0px inset; */
  height: 53px;
  border-bottom: 1px solid #101010;
}
header .MuiSvgIcon-root {
  color: #fff;
  font-size: 30px;
}
header .MuiButton-root:hover {
  background: transparent;
}
header .MuiAvatar-img {
  height: 30px;
  width: 30px;
  border-radius: 50px;
}
header .MuiAvatar-root.MuiAvatar-colorDefault {
  height: 30px;
  width: 30px;
  background: #fff;
}
header .MuiButton-text.dropdownMenu {
  padding: 10px 15px 10px 4px;
  min-width: auto;
}
.MuiButton-text.dropdownMenu {
  padding: 10px 20px;
}
header .MuiButton-label .MuiAvatar-root {
  width: 35px;
  height: 30px;
}

.paperAdminInfo {
  padding: 15px;
  margin: 0px;
  font-size: 15.5px;
  line-height: 21px;
}

.paperAdminInfo .email {
  color: #777;
}

.MuiList-root .MuiMenuItem-root {
  font-size: 0.9rem;
}
.MuiList-root .MuiMenuItem-root svg {
  color: #777779;
  padding-right: 10px;
}

.progress-div {
  /* background-color: rgb(233, 233, 233); */
  background-color: transparent;
  border-radius: 0.5rem;
}

.progress {
  background-color: #EBAE25;
  height: 4px;
  border-radius: 1rem;
  transition: 1.8s ease;
  transition-delay: 0.5s;
}

